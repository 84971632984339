<template>
  <div class="container">
    <div class="modal-header">
      <IconButton
        class="index-100"
        :van-button="{ background: 'rgba(14, 16, 18, 0.16)' }"
        :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }"
        @click="$router.go(-1)"
      />
      <h3 class="modal-title">Участники</h3>
    </div>
    <van-skeleton title :row="3" :loading="isSelfUserLoading">
      <ul class="participants-list">
        <li
          v-for="participant in participants"
          :key="participant.id"
          class="list-item"
          @click="getUser(participant.id)"
        >
          <div class="avatar-holder">
            <img
              :src="participant.avatar"
              :alt="`${participant.first_name} ${participant.last_name}`"
            />
          </div>
          {{ participant.first_name }} {{ participant.last_name }}
        </li>
      </ul>
    </van-skeleton>
  </div>
</template>

<script>
import ApiDeals from "@/api/deals.js";
import IconButton from "@/components/Global/IconButton.vue";

export default {
  props: {
    dealId: {
      type: (Number, String),
      default: null,
    },
  },
  data() {
    return {
      participants: null,
      isSelfUserLoading: true,
    };
  },
  components: {
    IconButton,
  },
  methods: {
    getUser(item) {
      this.$router.push(`/?user_id=${item}`);
    },
    getParticipants() {
      console.log(this.$route.params.id);
      this.isSelfUserLoading = true;
      ApiDeals.getParticipantsDeal(this.$route.params.id)
        .then((response) => {
          this.isSelfUserLoading = false;
          //  console.log(response.data);
          this.participants = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getParticipants();
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-bottom: 24px;

  .modal-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Prata";
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
  }
}
.participants-list {
  padding: 0 16px;

  .list-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #0e1012;
    padding: 16px 0 13px 0;
    border-bottom: 1px solid #f2f2f2;

    .avatar-holder {
      width: 24px;
      height: 24px;
      margin-right: 17px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
</style>
